import React, { memo ,useState,useEffect} from 'react';
import { useLocation ,useNavigate} from 'react-router-dom';
import { Input,Button,message} from 'antd';
import { UserOutlined} from '@ant-design/icons';
import logoWhite from '@img/logo-white.png'
import logo from '@img/logo.png'
import './index.css'
const Login = memo(()=>{
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false)
    let Username,Password

    const inputUserName=(e)=>{
        Username=e.target.value
    }
    const inputPassWord=(e)=>{
        Password=e.target.value
    }
    const onKeyDownchange=(e)=>{
        if(e.keyCode==13){
            login()
        }
    }
    const login=()=>{
        if(Username && Password){
            setLoading(true)
            window.ctx.login({Username:Username,Password:Password},res=>{
                if(res && res.Authorization){
                    window.ctx.cookieUserName(Username);
                    window.ctx.setUserActionLog({Action:"login"})
                    setLoading(false)
                    
                }else{
                    message.error(res.Message)
                    setLoading(false)
                }
            })
        }
    }
    return(
        <div className="module-login">
            <div className="login-view">
                <div className="content">
                    <img src={logo} className="logo-img"/> 
                    <div className="form">
                        <div><Input size="large" placeholder="Please input a username" suffix={<UserOutlined />} className="login-input" value={Username} onChange={inputUserName} /></div>
                        <div><Input.Password size="large" placeholder="Please input a password" className="login-input" value={Password} onChange={inputPassWord} onKeyDown={e=> onKeyDownchange(e)}/></div>
                        <Button className="btn" type="primary"  size="large" loading={loading} onClick={login}>Login</Button>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default Login



// class Module extends Component {
//     constructor(props) {
//         super(props);
//         this.state={
//             loading:false,
//             Username:"",
//             Password:""
//         }
//     }
//     componentDidMount() {
//         document.body.addEventListener('keydown',this.handleKeyDown);
//     }
//     login(){
//         let self=this
//         let {Username,Password}=self.state
//         if(Username && Password){
//             self.setState({loading:true})
//             window.ctx.login({Username:Username,Password:Password},res=>{
//                 if(res && res.Authorization){
//                     window.ctx.cookieUserName(Username);
//                     window.ctx.setUserActionLog({Action:"login"})
//                     self.setState({loading:false})  

//                 }else{
//                     message.error(res.Message)
//                     self.setState({loading:false})
//                 }
//             })
//         }
//     }
//     inputUserName(e){
//         this.setState({Username:e.target.value})
//     }
//     inputPassWord(e){
//         this.setState({Password:e.target.value})
//     }
//     onKeyDownchange(e) {
//         if(e.keyCode==13){
//             this.login()
//         }
//     }
//     render(){
//         let self=this
//         let {Username,Password}=self.state
//         return 
//     }
// }
