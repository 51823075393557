import React, { Component,lazy, Suspense,useEffect ,useState,useRef} from 'react';
import Api from './common/Api';
import Utils from './common/Utils';
import AppConf from './config/app_config';
import Context from './service/Context';
import Login from './module/login';
import ModuleRouter from './module/ModuleRouter';
import { message,Modal,notification} from 'antd';
import './css/App.css';
import './css/animate.css';
import './css/tree.css';
import './css/iconfont/iconfont.css';
import 'antd/dist/reset.css';
import axios from 'axios';
import dayjs from 'dayjs';
const serviceCache={}
let token=""
let initContext = (self,appCtxParam) => {
    let api = new Api(AppConf, appCtxParam.env);
    let ctx = new Context(api);
    ctx.PageSize=2000;
    ctx.setIntervalTime=5000;
    ctx.createService = function (ServiceClass){
        if(serviceCache[ServiceClass.displayName]){
            return serviceCache[ServiceClass.displayName];
        }else{
            return serviceCache[ServiceClass.displayName] = new ServiceClass(api);
        }
    };
    api.setGlobalError(function (err) {
        if(err.request && err.request.status!==200){
            switch (err.request.status){
                case 404:
                    ctx.tip('请求资源不存在！')
                    break;
                case 401:
                    window.ctx.signOut();
                    break;
                case 403:
                    token ? window.ctx.signOut():""
                    break;
                default:
                    ctx.tip('网络请求错误！')
            }
        }else{
            console.error(err);
        }
        
    })
    //全局提示
    ctx.message=(states,text)=>{

        return message[states](text);
    }
    ctx.modal=(content,fun)=>{
        Modal.confirm({
            title:"Tips",
            content:(content),
            cancelText:"Cancel",
            okText:"Confirm",
            onOk() {
                fun && fun();
            },
            onCancel(){},
        })
    }
    return ctx;
}
class App extends Component {
    constructor(props) {
        super(props);
        this.state={
            socket:null,
            flag:null,
            wsSentiment:{},
            wsNewsStream:{}
        }
        let self=this;
        let querysObj = Utils.getSearchObj();
        let env = Utils.getRunningENV(querysObj.env, AppConf.apiInstance.env, AppConf.apiInstance.hosts);
        let appCtxParam = {
            env: env,
            querys: querysObj
        };
        this.service = initContext(this, appCtxParam);
        if(window){window.ctx = self.service}
        token = self.service.cookieToken();
        if(token){
            let header={'Authorization':token}
            self.service.api.setGlobalHeaders(header);
            self.state = {iSLogin:true,appCtxParam: appCtxParam};
        }else{self.state = {iSLogin:false}} 
        self.service.addEventListener(self.service.events.token_success, function(e) {
			if(e.data.Authorization && e.data.Authorization.length) {
			    token = e.data.Authorization;
          self.service.api.setGlobalHeaders({'Authorization':token});
          self.service.cookieToken(token,new Date(token.expireTime));
          self.service.getUserInfo({},res=>{
              if(res){
                  self.service.loginUser=res[0];
                  self.setState({iSLogin:true,loginUser:self.service.loginUser});
                  self.service.cookieUserName(res[0]);
              }
          })
          self.getWbeMenu()
          // self.service.getMyWebMenu({},res=>{
          //     if(res){
          //         const webRouters=[]
          //         let treeMenu=Utils.newManageWebMenu(res);
          //         treeMenu.forEach((item=>{
          //             let menuObj={
          //                 path:item.WebMenuPath,
          //                 label:item.WebMenuName,
          //                 children:[],
          //             }
          //             item.children.forEach(cd=>{
          //                 const Element=lazy(()=>import(`./module/${cd.WebMenuElement}`)) 
          //                 let childrenObj={
          //                     path:cd.WebMenuPath,
          //                     label:cd.WebMenuName,
          //                     element: <Suspense fallback={<></>}><Element /></Suspense>,
          //                 }
          //                 menuObj.children.push(childrenObj)
          //             })
          //             webRouters.push(menuObj)
          //         }))
          //         self.setState({routersList:webRouters})
          //     }
          // })
			}
		});
    }
    componentDidMount() {
        const newsStream = new WebSocket('wss://test.cryptoblab.xyz/api/v1/info/news_stream');
        const sentiment = new WebSocket('wss://test.cryptoblab.xyz/api/v1/info/sentiment');
        newsStream.onopen = ()=> {
            console.log('客户端（news_stream）：与服务器的连接已打开')
            setInterval(()=> {
                if(newsStream.readyState==1){
                    newsStream.send(JSON.stringify({"type": "ping"}));
                }
            },10000)
        }
        newsStream.onmessage = (event) => {
            let data=JSON.parse(event.data)
            if(data?.type && data?.type=='pong'){
            }else{
                console.log(data)
                // setTimeout(()=>{
                    // this.setState({newsStreamData:data})
                    if (!('Notification' in window)) {
                        alert('你的浏览器不支持Notification')
                    }//检查是否拥有通知权限；有就通知，没有请求；
                    else if (Notification.permission=='granted') {
                        var notification1=new Notification('News_stream',{
                            icon:data.icon,
                            body:data.content ? data.content:data.title,
                            data:{
                                url:data.link,
                                status: "open",
                            },
                            requireInteraction:true,
                            renotify:true,
                            tag:"News_stream"
                        });
                        notification1.onclick = function(){
                            window.open(notification1.data.url, '_blank');  
                            notification1.close();              
                        }
                        setTimeout(notification1.close.bind(notification1), 20000);
                    }else if (Notification.permission !== 'denied'){
                        Notification.requestPermission(
                            function(permission){
                                if (permission=='granted'){
                                    // var notification=new Notification('hello wolrld!');
                                }
                            }
                        );
                    }
                // },"5000");
            }
        };
        newsStream.onclose = () => {
            newsStream.send(JSON.stringify({"type": "ping"}));
        };
        /////////////////////
        sentiment.onopen = ()=> {
            console.log('客户端（sentiment）：与服务器的连接已打开')
            setInterval(()=> {
                if(sentiment.readyState==1){
                    sentiment.send(JSON.stringify({"type": "ping"}));
                }
            },10000)
        }
        sentiment.onmessage = (event) => {
            let data=JSON.parse(event.data)
            if(data?.type && data?.type=='pong'){
            }else{
                console.log(data)
                // this.setState({sentimentData:data})
                if (!('Notification' in window)) {
                    alert('你的浏览器不支持Notification')
                }//检查是否拥有通知权限；有就通知，没有请求；
                else if (Notification.permission=='granted') {
                    var notification2=new Notification('Sentiment',{
                        body:`sentiment:${data.sentiment},\nanalysis:${data.analysis}`,
                        data:{
                            url:'https://asymmetriesformular.com/#/MarketAnalytics/BubbleAl',
                            status: "open",
                        },
                        requireInteraction:true,
                        renotify:true,
                        tag:"Sentiment"
                    });
                    notification2.onclick = function(){
                        window.open(notification2.data.url, '_blank'); 
                        notification2.close();              
                    }
                    setTimeout(notification2.close.bind(notification2), 20000);
                }else if (Notification.permission !== 'denied'){
                    Notification.requestPermission(
                        function(permission){
                            if (permission=='granted'){
                                // var notification=new Notification('hello wolrld!');
                            }
                        }
                    );
                }
            }
        };
        sentiment.onclose = () => {
            sentiment.send(JSON.stringify({"type": "ping"}));
        };
        this.getUserInfo();
        this.getTradingRule();
    }
    getSchedule(){
        this.search()
        this.timer =setInterval(()=>{this.search()},60000);
    }
    search() { 
        this.service.getSchedule({},res=>{
            // if(res){this.setState({ScheduleList:res})}
        })
    }
    notificationOnClose=(item)=>{
        let self=this
        let param={
            SetType:"Update",
            ScheduleID:item.ScheduleID,
            OwnerUserID:item.OwnerUserID,
            OwnerUsername:item.OwnerUsername,
            OperatorUserID:item.OperatorUserID,
            OperatorUsername:item.OperatorUsername,
            BeginDate:dayjs(item.BeginDate).format('YYYY-MM-DD HH:mm:ss'),
            EndDate:dayjs(item.EndDate).format('YYYY-MM-DD HH:mm:ss'),
            Subject:item.Subject,
            NotifyUsers:JSON.parse(item.NotifyUsers),
            AlarmMethod:item.AlarmMethod,
            CloseCheck:1
        }
        self.service.setSchedule(param,res=>{})
    }
    getUserInfo(){
        let self=this
        self.service.getUserInfo({},res=>{
            if(res){
                self.service.loginUser=res[0];
                this.getSchedule()
                self.service.cookieUserName(res[0]);
                self.setState({loginUser:self.service.loginUser})
                this.getWbeMenu();
            }
        })
    }
    getTradingRule(){
        let self=this;
        self.service.getTradingRule({},res=>{
            if(res){
                self.service.TradingRule=res
            }
        })
    }
    getWbeMenu(){
        let self=this;
        self.service.getMyWebMenu({},res=>{
            if(res){
                const webRouters=[]
                let treeMenu=Utils.newManageWebMenu(res);
                treeMenu.forEach((item=>{
                  if(item.WebMenuName=="Overview"){
                    const Element=lazy(()=>import(`./module/${item.WebMenuElement}`)) 
                    let menuObj={
                      path:item.WebMenuPath,
                      label:item.WebMenuName,
                      element:<Suspense fallback={<></>}><Element /></Suspense>,
                    }
                    webRouters.push(menuObj)
                  }else{
                    let menuObj={
                      path:item.WebMenuPath,
                      label:item.WebMenuName,
                      children:[],
                      element:null
                    }
                    item.children && item.children.forEach(cd=>{
                      const Element=lazy(()=>import(`./module/${cd.WebMenuElement}`)) 
                      let childrenObj={
                          path:cd.WebMenuPath,
                          label:cd.WebMenuName,
                          element: <Suspense fallback={<></>}><Element /></Suspense>,
                      }
                      menuObj.children.push(childrenObj)
                    })
                    webRouters.push(menuObj)
                  }
                }))
                self.setState({routersList:webRouters})
            }
        })
    }
    componentWillUnmount() {}
    render() {
        let self=this;
        return <div className="App">
            {self.state.iSLogin ? self.state.loginUser && self.state.routersList && <ModuleRouter parent={this} loginUser={self.state.loginUser} routersList={self.state.routersList}/>: <Login />}   
            {
                self.state.ScheduleList && self.state.ScheduleList.map((item)=>{
                    let today=dayjs().format('YYYY-MM-DD')
                    let BeginDate=dayjs(item.BeginDate).format('YYYY-MM-DD')
                    return today==BeginDate && item.CloseCheck!=1 && (JSON.parse(item.NotifyUsers)).indexOf(self.state.loginUser.UserID)!=-1 ? notification.info({
                        description:item.Subject,
                        duration: 60,
                        placement:"bottomRight",
                        onClose:()=>{
                            self.notificationOnClose(item)
                        }
                    }):null
                })
            } 
        </div>
    }
}
export default App;