import React, {useState,useMemo,useEffect,useCallback,useLayoutEffect,lazy,Suspense} from 'react';
import Utils from '@common/Utils';
import ComMainHeader from '@com/com-main-header';
import {useRoutes,useNavigate,useLocation} from 'react-router-dom';
import { Layout, Menu,Col, Row } from 'antd';
import { MenuOutlined} from '@ant-design/icons';
import localRouters from '../router/router';
import logoIcon from '@img/logo-icon.png';
import './component/com-main-header/index.css'
const { Header, Sider, Content } = Layout
const ModuleRouter = (props) => {
    let allRoutesList=(props.routersList).concat(localRouters)
    let element = useRoutes(allRoutesList)
    const loginUser=props.loginUser
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [routers,setRouters]=useState(localRouters);
    const [menuLoaded, setMenuLoaded] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const [innerWidth,setInnerWidth] =  useState(window.innerWidth);
    const selectedKeys = useMemo(() =>(routers.filter(rou=>rou.path==pathname)).length==0 ? [pathname]: pathname=='/'? ['/Overview/index']:[window.ctx.cookRouter()]);
    useEffect(() => {
        loadMenuData().finally(() => setMenuLoaded(true));
    }, []);
    useEffect(() => {
        (routers.filter(rou=>rou.path==pathname)).length==0 ? 
        (setOpenKeys(Utils.getNavigationParentRoutes(pathname,allRoutesList)),window.ctx.cookRouter(pathname)):""
    }, [pathname]);
    const loadMenuData = useCallback(async () => {
        try {
            Utils.updateFlatNavigationRoutes(allRoutesList); /** 更新扁平化路由数组 */
            setMenuData(Utils.navigationRoutesToMenuItems(allRoutesList));/** 用当前账号的路由转换出菜单 */
            
        } catch {
        }
    }, []);
    const onNavigate = useCallback((info) => {
        const routeInfo = Utils.getNavigationRouteInfo(info.key, Utils.flatNavigationRoutes);
        if (routeInfo) {
          navigate(routeInfo.path,{replace: false})
        }
        },[]
    );
    window.onresize=function(){ 
      window.addEventListener('resize', setInnerWidth(window.innerWidth));
    }
    return (
        <div className="module-content">
          {
            innerWidth>730 ? <ComMainHeader loginUser={loginUser}/> :
            <Header style={{ padding: 0}}>
              <Row style={{color:'white'}}>
                <Col span={3} className='header-menu'>
                  <MenuOutlined />
                  <div className='header-menu-show'>
                      {!!menuData.length && 
                      <Menu 
                          mode="inline"
                          openKeys={openKeys} 
                          selectedKeys={selectedKeys} 
                          items={menuData} 
                          onClick={onNavigate}
                          onOpenChange={setOpenKeys}
                      />}
                  </div>
                </Col>
                <Col span={15} style={{textAlign:'left'}}>
                    <img src={logoIcon} style={{width:'45px'}}/> Asymmetries Technologies
                </Col>
                <Col span={6}>
                  <span className="user-name" style={{fontSize:'12px'}}>{loginUser.Username}</span>
                </Col>
              </Row>
            </Header>
          }
          { 
            innerWidth>730 ?
              <Layout>
                  <Sider className='sider-view'>
                      {!!menuData.length && 
                      <Menu 
                          theme="dark" 
                          mode="inline"
                          openKeys={openKeys} 
                          selectedKeys={selectedKeys} 
                          items={menuData} 
                          onClick={onNavigate}
                          onOpenChange={setOpenKeys}
                      />}
                  </Sider>
                  <Layout style={{backgroundColor:'white'}}>
                      <div className="flex-content" style={{padding:'20px 10px',overflow:'hidden',height:'100%'}}>
                          {element && element}
                      </div>
                  </Layout>
              </Layout>:
              <Layout style={{backgroundColor:'white'}}>
                  <Content>
                    <div className="flex-content" style={{padding:'20px 10px',overflow:'hidden',height:'100%'}}>
                        {element && element}
                    </div>
                  </Content>
              </Layout>
          }
       </div>
    )
}
export default React.memo(ModuleRouter)
