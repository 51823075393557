import React, {useState,useMemo,useEffect,useCallback,useLayoutEffect,lazy,Suspense} from 'react';
import { Layout,Avatar,Modal,Form,Input,Button,} from 'antd';
import { UserOutlined,CaretDownOutlined,MenuOutlined} from '@ant-design/icons';
import logoIcon from '@img/logo-icon.png';
import './index.css';
const { Header} = Layout;
const MainHeader = (props) => {
  const [visible,setVisible]=useState(false);
  const loginUser=props.loginUser
  const [innerWidth,setInnerWidth] =  useState(window.innerWidth);

  const setModal=(visible)=> {
    const formItemLayout = {
        labelCol: {sm: { span: 7 },},
        wrapperCol: {sm: { span: 15 },},
    };
    const fromItemButtonLayout={wrapperCol:{offset: 8, span: 10 }};
    return <Modal open={visible} title="Reset Password" maskClosable={false} footer={null} onCancel={onModal}>
        <Form {...formItemLayout} initialValues={{ remember: true }} onFinish={onFromFinish}>
            <Form.Item label="Password"  name="Password" rules={[{ required: true, message: 'Please input Password!' }]}><Input.Password /></Form.Item>
            <Form.Item {...fromItemButtonLayout}>
                <Button htmlType="button" onClick={onModal} className="from-iten-btn">Cancel</Button>
                <Button type="primary" htmlType="submit" className="from-iten-btn">Submit</Button>
            </Form.Item>
        </Form>
    </Modal>
  }
  const onModal=()=>{
    setVisible(!visible)
  }
  const onFromFinish=(values)=>{
      window.ctx.setMyPassword(values,res=>{
          if(res){
              window.ctx.message("success","Reset Password Success");
              setTimeout(() => this.signOut(),500);
          }
      })
  }
  const signOut=()=>{
      window.ctx.signOut();
      window.ctx.setUserActionLog({Action:"loginOut"})
  }
  window.onresize=function(){ 
    window.addEventListener('resize', setInnerWidth(window.innerWidth));
  }
  return (
    <div className="module-con-main-header">
      <Header style={{ padding: 0,display:'flex',alignItems:'center'}}>
          <div className="header-logo">
              <img src={logoIcon} style={{width:'45px'}}/> Asymmetries Technologies
          </div>
          <div className="header-avatar">
              <div className="flex">
                  <span className="user-name">{loginUser.Username}</span>
                  <Avatar size={32} icon={<UserOutlined />}/>
                  <CaretDownOutlined style={{marginLeft:"10px"}}/>
              </div>
              <div className="header-personal">
                  <div className="row border-bottom" onClick={onModal}>Reset Password</div>
                  <div className="row" onClick={signOut}>Sign Out</div>
              </div>
          </div>
      </Header>
      {setModal(visible)}
  </div>
  )
}
export default React.memo(MainHeader)