import axios from 'axios';
import Utils from './Utils';
class Api{
    constructor(config,env){
        let _self=this;
        let _appConf=config;
        let _env=env;
        let _apiInstanceConf=_appConf.apiInstance.api;
        let _globalQuerys,
            _globalError,
            _globalHeaders=Object.assign({'Content-Type': 'application/json',}, _appConf.apiInstance.header || {});
        /********************** 请求全局设置 start  *********************/
        //设置全局请求头
        _self.setGlobalHeaders=function (obj) {
            let o=_globalHeaders;
            _globalHeaders=Object.assign(o,obj);
        };
        _self.getGlobalHeaders=function (obj) {
            return _globalHeaders;
        };
        //删除全局请求头
        _self.removeGlobalHeader=function (key) {
            if(_globalHeaders && _globalHeaders[key]!==undefined){
                Reflect.deleteProperty(_globalHeaders,key);
            }
        };
        //设置url全局查询参数
        _self.setGlobalQuerys=function (obj) {
            let o=_globalQuerys || {};
            _globalQuerys=Object.assign(o,obj);
        };
        //设置ajax全局错误回调函数
        _self.setGlobalError=function (fun) {
            _globalError=fun;
        };
        /********************** 请求全局设置 end  **************************/
        _self.transformApi=(instanceConf)=>{
            function renderApi (root, apiConfigs) {
                Object.keys(apiConfigs).forEach((key)=>{
                    let apiConf=apiConfigs[key];
                    if(apiConf.url && apiConf.method){
                        let _host=apiConf.localHost?apiConf.localHost:_env.apiHost;
                        root[key]=function (baseURL,axiosOption,param,callback,error) {
                            _self.sendRequest(baseURL,axiosOption,param,callback,error)
                        }.bind(_self,_host,apiConf);
                    }else{
                        let obj=root[key]={};
                        renderApi(obj,apiConf);
                    }
                });
            }
            renderApi(_self, instanceConf);
        };
        _self.transformApi(_apiInstanceConf || {});
        _self.axios=axios;
        _self.axios.defaults.timeout = 1000*20;
        let _defOpt={
            method:'get',
            baseURL:'',
            timeout:30000,
            responsetype:'json'
        }
        _self.sendRequest=(baseURL,option,param={},callback,error,retryCount)=>{
            retryCount = retryCount || 0;
            let useCache=false;
            if(_env.useCache && option.useCache){
                useCache=true;
            }
            let _customErrorCode=param['customCode'];
            if(_customErrorCode){
                Reflect.deleteProperty(param,'customCode');
            }
            if(param.useCache!==undefined){
                useCache=param.useCache? true:false;
                Reflect.deleteProperty(param,'useCache');
            }
            let _url=option.url;
            if(param.urlParams && typeof param.urlParams ==='object'){
                _url=option.url.format(param.urlParams);
                Reflect.deleteProperty(param,'urlParams');
            };
            let cacheData;
            let scopePath;
            let key
            if(useCache && callback){
                let path=_url;
                let startTime=Date.now();
                scopePath=Utils.hashCode(path)
                key = Utils.hashCode(JSON.stringify(param));
                cacheData=_self.getCache(scopePath,key);
                env.envKey!=='prod' && console.log('API [{path}] scopePath:[{scopePath}] key:[{key} ] process time use:[{use}]ms match data:[{hasData}]'.format({
                    path,
                    scopePath,
                    key,
                    hasData:cacheData?'yes':'no',
                    use:Date.now()-startTime 
                }))
            }
            if(cacheData){
                if(typeof callback ==='function'){
                    callback(cacheData);
                }
            }else{
                let opt=Object.assign(_defOpt,option);
                opt.baseURL=baseURL;
                opt.url=_url;
                opt.port=443;
                if(_globalHeaders){
                    opt.headers=_globalHeaders;
                }
                if(opt.method==='get'){
                    opt.params = Object.assign( _globalQuerys || {}, param || {});
                }else if(opt.method==='getDown'){
                    return window.open(opt.baseURL.concat(opt.url))
                }else {
                    opt.params = _globalQuerys || {};
                    opt.data = param;
                }
                let successCall=function(res){
                    if(useCache){
                        if(res.data && !(res.data._notice || res.data._error)){
                            _self.setCache(scopePath,key,res.data);
                        }
                    }
                    callback && callback(res.data);
                }
                let errorCall=function(err){
                    if(err && err.response && err.response.data && err.response.data._notice){
                        callback && callback(err.response.data);
                    }else if(err && err.request){
                        if(!err.request.status && retryCount<=3){
                            setTimeout(()=>{
                                retryCount++;
                                console.debug('retry:'+retryCount);
                                let promiss = axios(opt);
                                promiss.then(successCall).catch(errorCall);
                            },500)
                            return false;
                        }else{
                            if(typeof error === 'function'){
                                error(err);
                            }else if (typeof _globalError === 'function') {
                                if(_customErrorCode && _customErrorCode[err.request.status]){
                                    _customErrorCode[err.request.status](err);
                                }else{
                                    _globalError(err);
                                }
                            }
                        }
                    }
                }
                let promiss = axios(opt);
                if(typeof callback === 'function'){
                    promiss.then(successCall).catch(errorCall);
                }else{
                    return promiss;
                }
            }
        };

    }
}
export default Api;
