import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,HashRouter} from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import App from './App';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import reportWebVitals from './reportWebVitals';
import './css/index.css';
ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <ConfigProvider locale={enUS}>
                <App />
            </ConfigProvider>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
