import React,{ lazy, Suspense } from 'react';
const Overview = lazy(() => import('../module/Overview/index'));
const MonitorProduct = lazy(() => import('../module/Monitor/Products/index'));
const MonitorProductDetail = lazy(() => import('../module/Monitor/Products/product-detail'));
const ProductListNetValue = lazy(() => import('../module/Product/ProductList/product-netValueList'));
const ProductListDetail = lazy(()=>import('../module/Product/ProductList/product-details'));
const ProductListRecord = lazy(()=>import('../module/Product/ProductList/product-record'));
const PortfolioStrategy = lazy(()=>import('../module/Defi/Portfolio/portfolio-strategy'));
const PortfolioStates = lazy(()=>import('../module/Defi/Portfolio/portfolio-netvalue'));
const FundingRateOptional= lazy(()=>import('../module/MarketAnalytics/FundingRate/fundingRate-optional'));
const routes = [
    {
      path: "/",
      hideInMenu: true,
      element: <Suspense fallback={<></>}><Overview /></Suspense>,
    },
    {
        path: "/Monitor/Product/Detail",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><MonitorProductDetail /></Suspense>,
    },
    {
        path: "/Product/ProductList/NetValue",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><ProductListNetValue /></Suspense>,
    },
    {
        path: "/Product/ProductList/Detail",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><ProductListDetail /></Suspense>,
    },
    {
        path: "/Product/ProductList/Record",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><ProductListRecord /></Suspense>,
    },
    {
        path: "/Defi/Portfolio/Strategy",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><PortfolioStrategy /></Suspense>,
    },
    {
        path: "/Defi/Portfolio/States",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><PortfolioStates /></Suspense>,
    },
    {
        path: "/FundingRate/Optional",
        hideInMenu: true,
        element:<Suspense fallback={<></>}><FundingRateOptional /></Suspense>,
    }
]

export default routes
