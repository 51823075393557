import Service from '../common/Service';
import Cookies from 'js-cookie';
const displayName='ContentService';
class ModuleService extends Service{
    constructor(api){
        super(api);
        let self=this
        self.events={token_success:'token_success'}
        let defExpires=7;
        //缓冲
        self._cookie=function(key,value,expires){
            if(value === undefined){
                return Cookies.get(key);
            }else if(value === '') {
                Cookies.remove(key);
            }else{
                Cookies.set(key,value,{expires:expires});
            }
        };
        //token
        self.cookieToken=function(value,expires){
            return self._cookie('_asy_',value,expires || defExpires);
        };
        self.cookieUserName=function(value){
            return self._cookie('_user_',value);
        };
        self.cookieUseTrade=function(value){
            return self._cookie('_trade_',value);
        };
        self.cookieUseBacktest=function(value){
            return self._cookie('_backtest_',value);
        };
        self.walletTransactions=function(value){
            return self._cookie('_transactions',value);
        };
        self.cookRouter=function(value){
            return self._cookie('_router',value);
        };
        self.login=function(param,callback,error){
            self.api.login(param,function (res) {
                if(res.Authorization){
                    self.dispatchEvent(self.events.token_success,res)
                }
                callback && callback(res);
            },error)
        };
        self.signOut=function(){
            self.cookieToken('');
            window.location.hash="";
            window.location.reload();
        };
        self.getUserInfo=function(param,callback,error){
            self.api.getUserInfo(param,function (res) {
                callback && callback(res);
            })
        };
        self.setMyPassword=function(param,callback,error){
            self.api.setMyPassword(param,function (res) {
                callback && callback(res);
            })
        };
        self.getMyWebMenu=function(param,callback,error){
            self.api.getMyWebMenu(param,function (res) {
                callback && callback(res);
            })
        };
        self.getTradingRule=function(param,callback){
            self.api.getTradingRule(param,function (res) {
                callback && callback(res);
            })
        };
        self.setUserActionLog=function(param,callback,error){
            self.api.setUserActionLog(param,function (res) {
                callback && callback(res);
            })
        };
        self.getUserActionLog=function(param,callback,error){
            self.api.getUserActionLog(param,function (res) {
                callback && callback(res);
            })
        };
        self.setSchedule=function(param,callback,error){
            self.api.setSchedule(param,function (res) {
                callback && callback(res);
            })
        };
        self.getSchedule=function(param,callback,error){
            self.api.getSchedule(param,function (res) {
                callback && callback(res);
            })
        };
    }
}
ModuleService.displayName=displayName;
export default ModuleService
